import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Loader from 'react-loader-spinner'
import MediaList from './MediaList'
import Popup from './popup'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import { API } from 'aws-amplify'

export default class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: {},
      isLoading: false,
      currentCategory: '-1',
      message: '',
      showModal: false,
      linkUrl: '',
      currentIsbns: ['', '', '', '', '', '']
    }
    this.apiName = 'ppstaffpicks'
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const categoryItems = await this.listCategories();
    await this.setState(prevState => {
      return {
        ...prevState,
        categories: categoryItems,
        isLoading: false
      }

    })
  }

  listCategories = () => {
    const token = sessionStorage.getItem("id");
    const path = '/categories';
    const payload = {
      headers: {},
      response: true,
      queryStringParameters: {
        identifier: token,
      },
    };

    return API
      .get(this.apiName, path, payload)
      .then(response => {
        if (response.status === 200) {
          this.setState({ linkUrl: response.data.url });
          return response.data.categories;
        }
        else {
          return [];
        }
      }).catch(error => {
        console.log(error);
      });
  }

  getCategoryItems = (category) => {
    const token = sessionStorage.getItem("id");
    const payload = {
      headers: {},
      response: true,
      queryStringParameters: {
        identifier: token,
        category: category
      },
    };

    return API.get(this.apiName, "/categories", payload).then(response => {
      if (response.status === 200) {
        return response.data;
      }
      else {
        return [];
      }
    }).catch(error => {
      console.log(error);
      return [];
    });
  }

  setCurrentCategory = async (e) => {
    const books = await this.getBookInfoForCategory(e.target.value)

    const isbns = books.map(book => {
      return book
    })

    const newIsbnArray = this.state.currentIsbns.map((isbn, index) => {
      if (isbns[index]) {
        return isbns[index]
      } else {
        return ''
      }
    })

    this.setState(prevState => {
      return {
        ...prevState,
        currentIsbns: newIsbnArray,
        currentCategory: e.target.value
      }
    })
  }

  clearCurrentCategoryIsbns = (e) => {
    const newIsbnArray = this.state.currentIsbns.map((isbn, index) => {
      return {
        'isbn': '',
        'title': '',
        'author': '',
        'idType': '',
        'imageSrc': '',
        'link': ''
      }
    })

    this.setState(prevState => {
      return {
        ...prevState,
        currentIsbns: newIsbnArray
      }
    })
  }

  getBookInfoForCategory = (category) => {
    const token = sessionStorage.getItem("id");
    const payload = {
      headers: {},
      response: true,
      queryStringParameters: {
        identifier: token,
        category: category
      },
    };

    return API.get(this.apiName, "/categories", payload).then(response => {
      if (response.status === 200) {
        return response.data;
      }
      else {
        return [];
      }
    }).catch(error => {
      console.log(error);
      return [];
    });
  }

  setShowModal = () => {
    this.setState({ showModal: false })
  }

  onIsbnChange = (title, index) => {
    const newIsbns = this.state.currentIsbns.slice()

    newIsbns[index] = {
      ...title
    }

    this.setState(prevState => {
      return {
        ...prevState,
        currentIsbns: newIsbns
      }
    })
  }

  validateIsbns(currentCategory, currentIsbns) {
    if (currentCategory === '-1') {
      this.setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          showModal: true,
          message: 'Please select a category to continue.'
        }
      })
      return
    }

    if (currentIsbns.includes('')) {
      this.setState(prevState => {
        return {
          ...prevState,
          showModal: true,
          message: 'Must submit an ISBN for all 6 fields.',
          isLoading: false
        }
      })

      return
    }
  }

  saveRecord = (e) => {
    e.preventDefault()
    this.setState({ isLoading: true })

    const { currentCategory, currentIsbns, categories } = this.state;

    this.validateIsbns(currentCategory, currentIsbns)

    let hydratedIsbns = []

    API.get(this.apiName, '/summary',
      {
        'queryStringParameters': {
          'isbns': this.state.currentIsbns.map(i => i.isbn).join(','),
          'identifier': sessionStorage.getItem("id")
        }
      }
    ).then(summaryResponse => {
      summaryResponse.isbns.forEach(work => {
        const titleData = this.createTitleData(work, summaryResponse)
        hydratedIsbns.push(titleData)
      })

      console.log('after refreshing isbns', hydratedIsbns)
      const token = sessionStorage.getItem("id");
      const payload = {
        headers: {},
        response: true,
        queryStringParameters: {
          identifier: token,
          category: currentCategory
        },
        body: {
          content: hydratedIsbns.map(isbn => {
            const idType = isbn['idType'] ? isbn['idType'] : 'isbn'
            return {
              'isbn': isbn['isbn'],
              'bookJacketUrl': isbn['bookJacketUrl'],
              'idType': idType,
              'author': isbn['author'],
              'title': isbn['title'],
              'alt': '',
              'summary': isbn['summary'],
              'isUserInput': isbn['isUserInput']
            }
          })
        }
      };


      const response = API.post(this.apiName, "/categories", payload).then(response => {
        this.setState(prevState => {
          return {
            ...prevState,
            showModal: true,
            message: `The category "${categories[currentCategory]}" has been saved.`,
            isLoading: false,
            currentIsbns: hydratedIsbns
          }
        })
      }).catch(error => {
        this.setState(prevState => {
          return {
            ...prevState,
            showModal: true,
            message: 'Books failed to save. Please try again.'
          }
        })
      });
    })
  }

  createTitleData(work, response) {
    let titleData = {}

    if (work.bowker_data) {
      titleData = this.titleConfig(work.isbn, work.bowker_data.title, work.bowker_data.author, 'isbn')

      const bookJacketUrl = response['book_jacket_url']

      if (bookJacketUrl) {
        titleData = {
          ...titleData,
          'imageSrc': bookJacketUrl.replace('{isbn}', work.isbn)
        }
      }

      if (response?.show_summary) {
        if (work.bowker_data.annotation) {
          const text = this.formatSummaryText(work.bowker_data.annotation)
          titleData = {
            ...titleData,
            'summary': text
          }
        } else {
          titleData = {
            ...titleData,
            'summary': 'This title has no summary. Please select another one.'
          }
        }
      }
    } else {
      titleData = {
        ...this.titleConfig(work.isbn, '', '', 'isbn'),
        'summary': ''
      }
    }

    return titleData
  }

  titleConfig(isbn, title, author, idType) {
    let [updatedTitle, updatedAuthor, isUserInput] = this.reconcileTitleAndAuthor(isbn, title, author)

    updatedAuthor = updatedAuthor.replaceAll(/[(].+?[)]/ig, '').replaceAll(';', '')

    return {
      'isbn': isbn,
      'title': updatedTitle,
      'author': updatedAuthor,
      'isUserInput': isUserInput,
      'idType': idType,
      'imageSrc': `https://secure.syndetics.com/index.aspx?isbn=${isbn}/lc.gif&client=patronpointsyn&type=unbound&nicaption=Image`,
      'link': this.state.linkUrl ? this.state.linkUrl.replace("{ISBN}", isbn).replace("{search_keywords}", `${updatedTitle} ${updatedAuthor}`) : ''
    }
  }

  reconcileTitleAndAuthor(isbn, title, author) {
    // if title/author is not empty and bowker data doesn't match
    // use the title/author that's in state (aka the one input by the user)

    const work = this.state.currentIsbns.filter(w => w.isbn == isbn)[0]
    const isUserInput = work.isUserInput === true ? true : false

    // if bowker title is undefined then use the user input title
    if (title == undefined || title == '' || (isUserInput && work.title != '')) {
      title = work.title
    }

    // if work title is undefined then use the user input title
    if (author == undefined || author == '' || (isUserInput && work.author != '')) {
      author = work.author
    }

    return [title, author, isUserInput]
  }

  formatSummaryText(annotation) {
    const text = annotation.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ')

    if (text.length <= 400) {
      return text
    } else {
      const words = text.split(' ')
      const wordsToUse = []

      let counter = 0
      do {
        wordsToUse.push(words[counter])
        counter += 1
      } while (wordsToUse.join(' ').length < 400 && counter < words.length)

      return `${wordsToUse.join(' ')}...`
    }
  }

  render() {
    return (
      <>
        <Popup showModal={this.state.showModal} message={this.state.message} setShowModal={this.setShowModal} />
        {this.state.isLoading &&
          (<Loader
            type="TailSpin"
            color="#5D0E4E"
            height={100}
            width={100}
            style={{
              position: 'fixed',
              zIndex: '2',
              left: '50%',
              top: '50%',
              marginTop: '-50px',
              marginLeft: '-50px'
            }}

          />)
        }

        <Container>
          <Row>
            <Col xs={12}>
              <div className="category-label">Select a category</div>
              <div className="category-div">
                <select
                  className="select-css"
                  name="category"
                  onChange={e => this.setCurrentCategory(e)}
                >
                  <option value="-1">Select a category</option>
                  {Object.entries(this.state.categories).map(category => {
                    const key = category[0]
                    const value = category[1]
                    return (<option key={key} value={key}>{value}</option>)
                  })}
                </select>
                <Button
                  disabled={this.state.isLoading}
                  className="button-size"
                  onClick={e => this.saveRecord(e)}>
                    Refresh & Save
                </Button>
                <Button
                  disabled={this.state.isLoading}
                  className='button-size btn-secondary'
                  onClick={e => this.clearCurrentCategoryIsbns(e)}
                  variant='secondary'
                >
                  Clear All
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        {
          this.state.currentCategory === '-1'
            ? null
            : <Container>
                <div className='row justify-content-around'>
                  <MediaList
                    titles={this.state.currentIsbns}
                    onIsbnChange={this.onIsbnChange}
                    linkUrl={this.state.linkUrl}
                    apiDomain={this.apiName} />
                </div>
              </Container>
        }
      </>
    )
  }
}
