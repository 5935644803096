import React, { useState, useEffect, Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams
} from "react-router-dom";

import { Card, Button, Container, Col, Row } from 'react-bootstrap'
import { Icon } from 'react-fa'
import image from './images/patron_logo_lb200.png'
import Loader from 'react-loader-spinner'
// import LoginPage from './LoginPage';
import Header from './components/header';
import Main from './components/pages/main';

import { v4 as uuidv4 } from 'uuid';
import Keycloak from 'keycloak-js';

import Amplify, { API } from '@aws-amplify/core';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


export default function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthentication());
  const [client, setClient] = useState(null);

  const clientCallback = async (value) => {
    setClient(value);
    const id = btoa(`${client}:`)
    sessionStorage.setItem("id",id);
  }

  const authenticate =  async (value) =>{
    console.log(value);
    setIsAuthenticated(value);
  };

  function checkAuthentication (){
    const token = localStorage.getItem('token');
    if (token===null)
      return false;
    const state = token.split(":")[1]
    const kc_cb = localStorage.getItem(`kc-callback-${state}`);
    return kc_cb!==null;
  }

  return (
    <Router>
      <div>
        <Switch>
        <Route path='/auth' children={<Secured authCallback={authenticate}/>} />
        <Route path="/main">
          { isAuthenticated 
            ?
              <div>
                <Header />
                <Main />
              </div> 
            :
              <Redirect to="/auth" />
          }
        </Route>
        <Route exact path='/token/:hash' children={<TokenHandler authCallback={authenticate}/>} />
        <Route exact path='/:client' children={<ClientHandler />} />
        <Route exact path='/' children={<RootHandler  appClient={client} reloadCallback={clientCallback}/>} />
        </Switch>
      </div>
    </Router>
  );
}

const RootHandler = (props) => {

  const id = sessionStorage.getItem("id");
  const client=props.appClient;
  // let client = null;
  // if (id!==null){
  //   client = atob(id).split(":")[0];
  // }

  return (
    <div>
    { client ?
      <Redirect to={`/${client}`} /> :
      <ClientSetter reloadCallback={props.reloadCallback} />
    }
    </div>
  )
}

const ClientSetter = (props) => {

  const { reloadCallback } = props;
  const [clientID, setClientID] = useState('');
  const [error, setError] = useState('Your login information is incorrect.')

  const handleKey = (e) => {
      if (e.which === 13) {
          signIn(e);
      }
  }

  const signIn = async (e) => {
    if (!clientID) {
        setError('Please complete the form to continue');
        return 1;
    }
    reloadCallback(clientID);
  }

  return (
    <Card style={{ width: '25rem', margin: 'auto', top: '50px' }}>
      <Card.Img variant="top" src={image} style={{ width: 200, margin: 'auto' }}/>
      <Card.Body>
        <form onKeyDown={e => handleKey(e)} onSubmit={e => signIn(e)}>
            <Container>
                <Row>
                    <Col style={{ paddingBottom: '35px'}}>
                        <Icon name="building" style={{ padding: '0.9rem 0.5rem', backgroundColor: '#EBEDF0' }}/>
                        <input type="text" style={{ width: '90%', height: '45px' }} onChange={e => setClientID(e.target.value) }/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button 
                          style={{ width: '100%', height: '45px', fontWeight: '700' }} 
                          variant="primary"
                          onClick={ e => signIn(e) }
                        >Continue</Button>
                    </Col>
                </Row>
            </Container>
        </form>
      </Card.Body>
    </Card>
  )
}


const ClientHandler = (props) => {

  let { client } = useParams();
  const id = btoa(`${client}:`)
  sessionStorage.setItem("id",id);

  return (
    <Redirect to="/main" />
  )
}


const TokenHandler = (props) => {
  let { hash } = useParams();
  // let kc_cb=null;
  const token = atob(hash);
  localStorage.setItem("token",token)
  const state = token.split(":")[1];
  const kc_cb = localStorage.getItem(`kc-callback-${state}`);
  // useEffect( () => {
  //   props.authCallback(kc_cb!==null)
  // })
  props.authCallback(kc_cb!==null);

  return (
     <Redirect to="/main" />
  )
}


class Secured extends Component {
  constructor(props) {
    super(props);
    this.state = { keycloak: null, authenticated: false, token: null };
  }

  getEnvironmentAuthConfig() {
    const isDev = ['dev', 'localhost'].some(env => window.location.href.includes(env))
    if (isDev) {
      return {
        redirectUri: 'https://w0gzox9grk.execute-api.us-east-2.amazonaws.com/dev',
        clientId: 'patron_point_application_2'
      }
    } else {
      return {
        redirectUri: 'https://v28l8i3p22.execute-api.us-east-2.amazonaws.com/prod',
        clientId: 'patron_point_application'
      }
    }
  }

  componentDidMount() {
    const authConfig = this.getEnvironmentAuthConfig()
    const keycloak = Keycloak({
      "realm": "WMK",
      "url": "https://ssoauthentification.com/auth",
      "clientId": authConfig.clientId
    });
    const kcInit ={
      onLoad: 'login-required',
      redirectUri: `${authConfig.redirectUri}/auth`, 
      responseMode: "query",
      scope: "profile email"
    }
    keycloak.init(kcInit).then((authenticated) => {});
    localStorage.setItem("logoutURL",keycloak.createLogoutUrl());
    this.props.authCallback(true);
  }

  render() {
    return (
      <div>
        <Loader
          type="TailSpin"
          color="#5D0E4E"
          height={100}
          width={100}
          style={{ 
              position: 'fixed',
              zIndex: '2', 
              left: '50%', 
              top: '50%', 
              marginTop: '-50px', 
              marginLeft: '-50px' }}
        />
      </div>
    );
  }
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// function PrivateRoute({ children, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         localStorage.getItem('loggedIn') === 'true' ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/",
//               state: { from: location }
//             }}
//           />
//         )
//       }
//     />
//   );
// }

// function ProtectedPage() {
//   return <h3>Protected</h3>;
// }
