import React, { useState, useEffect } from 'react'
import { Row, Form } from 'react-bootstrap'

export default function Media({ title, onSetFormInput, index, linkUrl, apiDomain }) {
  const [titleConfig, setTitleConfig] = useState({})

  useEffect(() => {
    if (title['isbn'] === '') {
      setTitleConfig({
        'isbn': '',
        'title': '',
        'author': ''
      })
    } else {
      let updatedTitle = {}
      if (!title.imageSrc || !title.link) {
        updatedTitle = {
          ...title,
          'imageSrc': `https://secure.syndetics.com/index.aspx?isbn=${title.isbn}/lc.gif&client=patronpointsyn&type=unbound&nicaption=Image`,
          'link': linkUrl ? linkUrl.replace("{ISBN}", title.isbn).replace("{search_keywords}", `${title.title} ${title.author}`) : ''
        }
      } else {
        updatedTitle = {
          ...title
        }
      }

      setTitleConfig(updatedTitle)
    }
  }, [title])

  function config(isbn, title, author, idType) {
    return {
      'isbn': isbn,
      'title': title,
      'author': author,
      'idType': idType,
      'imageSrc': `https://secure.syndetics.com/index.aspx?isbn=${isbn}/lc.gif&client=patronpointsyn&type=unbound&nicaption=Image`,
      'link': linkUrl ? linkUrl.replace("{ISBN}", isbn).replace("{search_keywords}", `${title} ${author}`) : ''
    }
  }

  function handleIsbnInput(e) {
    let updatedConfig = config(e.target.value, '', '', 'isbn')
    setTitleConfig(updatedConfig)
    onSetFormInput(updatedConfig, index)
  }

  function handleFormInput(e, key) {
    let updatedConfig = {
      ...titleConfig,
      [key]: e.target.value,
      'isUserInput': true
    }

    setTitleConfig(updatedConfig)
    onSetFormInput(updatedConfig, index)
  }

  return (
    <div className="col-3 card m-2">
      <div className="card-body">
        <Form>
          <Form.Group>
            <Row xs={12} sm={12} md={4} lg={4} style={{ paddingBottom: '10px'}}>
              <Form.Control
                type="text"
                value={titleConfig.isbn}
                onChange={(e) => handleIsbnInput(e)}
                placeholder="ISBN"
              />
            </Row>
            <Row xs={12} sm={12} md={4} lg={4} style={{ paddingBottom: '10px'}}>
              <Form.Control
                type="text"
                value={titleConfig.title}
                onChange={(e) => handleFormInput(e, 'title')}
                placeholder="Title"
              />
            </Row>
            <Row xs={12} sm={12} md={4} lg={4} style={{ paddingBottom: '10px'}}>
              <Form.Control
                type="text"
                value={titleConfig.author}
                onChange={(e) => handleFormInput(e, 'author')}
                placeholder="Author"
              />
            </Row>
            {
              titleConfig
              ?
              <Row>
                <a href={titleConfig.link} target="_blank" rel="noopener noreferrer">
                  <img src={titleConfig.imageSrc} className="card-img" max-height="10px" alt={titleConfig.isbn} />
                </a>
              </Row>
              : null
            }
            {
              (titleConfig.summary != undefined || titleConfig.summary != '')
              ?
              <Row style={{ marginTop: '10px', wordBreak: 'break-word' }}>
                <p>
                  {titleConfig.summary}
                </p>
              </Row>
              : null
            }
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}
