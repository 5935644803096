import React from "react"
import { Button } from 'react-bootstrap'
import PropTypes from "prop-types"
import image from '../images/pp_image.png'

const Header = (props) => {
  const location = document.location;
  const token = localStorage.getItem('token');
  const handleExit = () => {
    const state=token.split(":")
    const url = localStorage.getItem('logoutURL');
    window.location.assign(url)
    localStorage.removeItem('token')
    localStorage.removeItem(`kc-callback-${state}`)
    // location.replace(`https://${location.hostname}`)
  }

  return (
    <header
      style={token === null ? 
      { backgroundColor: '#F8F8F8',
        marginBottom: '6rem'} : 
      { backgroundColor: '#FFF',
      paddingTop: '0.5rem',
      marginBottom: `1.45rem`,
      height: '70px'}}
    >
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {token !== null && <img style={{ width: 250, marginLeft: '5%', height: '100%' }} src={image} />}
        {token !== null && <Button variant="primary" onClick={handleExit} style={{ marginRight: '5%', marginBottom: '12px' }}>Logout</Button>}
      </span>
    </header>
)
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
