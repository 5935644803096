import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

function Popup({ showModal, message, setShowModal }) {
    const [show, setShow] = useState(showModal);
    const handleClose = () => {
        setShow(false);
        setShowModal();
    }

    useEffect(() => {
        setShow(showModal);
    }, [showModal])
  
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default Popup;