import Media from './Media'

export default function MediaList({ onIsbnChange, titles, linkUrl, apiDomain }) {
  return titles.map((title, index) => {
    return (
      <Media
        index={index}
        title={title}
        onSetFormInput={onIsbnChange}
        linkUrl={linkUrl}
        apiDomain={apiDomain} />
    )
  })
}
